// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-barriere-melden-js": () => import("./../../../src/pages/barriere-melden.js" /* webpackChunkName: "component---src-pages-barriere-melden-js" */),
  "component---src-pages-datenschutzhinweis-js": () => import("./../../../src/pages/datenschutzhinweis.js" /* webpackChunkName: "component---src-pages-datenschutzhinweis-js" */),
  "component---src-pages-erklaerung-zur-barrierefreiheit-js": () => import("./../../../src/pages/erklaerung-zur-barrierefreiheit.js" /* webpackChunkName: "component---src-pages-erklaerung-zur-barrierefreiheit-js" */),
  "component---src-pages-gebaerdensprache-js": () => import("./../../../src/pages/gebaerdensprache.js" /* webpackChunkName: "component---src-pages-gebaerdensprache-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-leichte-sprache-js": () => import("./../../../src/pages/leichte-sprache.js" /* webpackChunkName: "component---src-pages-leichte-sprache-js" */)
}

