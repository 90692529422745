module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"remarkPlugins":[[null,{"target":"_blank","content":{"type":"element","tagName":"i","children":[{"type":"text","value":" (öffnet ein neues Fenster)"}]},"contentProperties":{"class":"sr-only"}}]],"extensions":[".mdx"],"defaultLayouts":{},"gatsbyRemarkPlugins":[],"lessBabel":false,"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/nkosakul/PhpstormProjects/bpa-quiz-app/frontend-landingpage"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Bundespresseamt - Wie klickst du?","short_name":"Bundespresseamt - Wie klickst du?","start_url":"/","description":"Bundespresseamt - Wie klickst du?","background_color":"#ffb429","theme_color":"#ffb429","display":"standalone","lang":"de","icons":[{"src":"/favicon.ico","sizes":"16x16","type":"ico"},{"src":"/favicons/favicon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"/favicons/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"/favicons/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/android-chrome-512x512.png","sizes":"512x512","type":"image/png"},{"src":"/favicons/apple-touch-icon.png","sizes":"180x180","type":"image/png"},{"src":"/favicons/mstile-70x70.png","sizes":"70x70","type":"image/png"},{"src":"/favicons/mstile-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/favicons/mstile-150x150.png","sizes":"150x150","type":"image/png"},{"src":"/favicons/mstile-310x310.png","sizes":"310x310","type":"image/png"},{"src":"/favicons/mstile-310x150.png","sizes":"310x150","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
